<template>
    <v-container id="contact">
        <v-row>
            <v-col cols="12" md="6">
                <h1 class="text-h1 d-none">Network Black Hole Contacts</h1>
                <v-card class="pa-4" xs-12 sm-12 md-6 >
                    <v-card-title>Technical Support</v-card-title>
                    <v-card-subtitle>Have a technical question or need help? Contact us at:</v-card-subtitle>
                    <v-card-text>
                        <a :href="`mailto:support@${constants.domain}`">support@{{ constants.domain }}</a>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="pa-4" xs-12 sm-12 md-6>
                    <v-card-title>General contact</v-card-title>
                    <v-card-subtitle>For everything else send us an email to:</v-card-subtitle>
                    <v-card-text>
                        <a :href="`mailto:hello@${constants.domain}`">hello@{{ constants.domain }}</a>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-card class="pa-4">
                    <v-card-title>Snail mail</v-card-title>
                    <v-card-subtitle></v-card-subtitle>
                    <v-card-text>
                        <ul class="address pl-0">
                            <li>{{ constants.app_name }}</li>
                            <li><strong><a href="https://www.smartnetworkingsolutions.uk">Smart Networking Solutions</a></strong> Ltd</li>
                            <li>27 Old Gloucester Street</li>
                            <li>London WC1N 3AX</li>
                            <li>United Kingdom</li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useConstants } from "@/const/const.js";
const constants = useConstants();

export default {
    name: "ContactView",
    components: {},
    setup() {
        return {
            constants,
        };
    },
    metaInfo() {
        return {
            title: constants.app_name,
            titleTemplate: "%s | Contact",
            link: [
                {rel: "canonical", href: `https://${constants.web_url}/contact`}
            ]
        };
    },
};
</script>
